@import url("https://fonts.googleapis.com/css2?family=Work+Sans:wght@300;400;500;600;800&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

#lr_show_video {
  position: relative !important;
}

.score-table svg {
  display: inline-block;
}

.score-table tr td {
  text-align: center;
  padding-top: 1rem;
  padding-bottom: 1rem;
}
